import { gql } from "@apollo/client";

export const ALL_TODOS_QUERY = gql`
    query GetAllTodoItems($todoListId: ID) {
        getAllTodoItems(todoListId: $todoListId) {
            id
            title
            description
            completed
            lastCompletedAt
            createdAt
            archived
            order
            tags
        }
    }
    `
export const GET_TODO_QUERY = gql`
    query GetTodoItem($id: ID!) {
        getTodoItem(todoItemId: $id) {
            id
            title
            description
            completed
            lastCompletedAt
            createdAt
            archived
            order
            tags 
        }
    }
    `

export const SAVE_TODO_MUTATION = gql`
    mutation SaveTodo($id: ID!, $title: String!, $description: String!) {
        updateTodoItem(todoItemId: $id, todoItemInput: {title: $title, description: $description}) {
            success
            todoItem {
                id
                title
                description
                completed
                lastCompletedAt
                createdAt
                archived
                tags
            }
        }
    }
    `

export const TOGGLE_COMPLETE_ITEM_MUTATION = gql`
    mutation ToggleComplete($id: ID!) {
        toggleCompleteTodoItem(todoItemId: $id) {
            success
            todoItem {
                id
                title
                description
                completed
                lastCompletedAt
                createdAt
                archived
                order
                tags
            }
        }
    }
    `

export const TOGGLE_ARCHIVE_ITEM_MUTATION = gql`
    mutation ToggleArchive($id: ID!) {
        toggleArchiveTodoItem(todoItemId: $id) {
            success
            todoItem {
                id
                title
                description
                completed
                lastCompletedAt
                createdAt
                archived
                order
                tags
            }
        }
    }
    `

export const ADD_TODO_MUTATION = gql`
    mutation AddTodo($text: String!, $description: String!, $todoListId: ID) {
    createTodoItem(todoItemInput: { title: $text, description: $description}, todoListId: $todoListId) {
        success
        todoItem {
            id
            title
            description
            completed
            lastCompletedAt
            createdAt
            tags
            archived
            order
            }
        }
    }
    `

export const MOVE_TODO_MUTATION = gql`
    mutation MoveTodoItem($id: ID!, $toIndex: Int!) {
        moveTodoItem(todoItemId: $id, toIndex: $toIndex) {
            success
            todoItem {
                id
                title
                description
                completed
                lastCompletedAt
                createdAt
                archived
                order
                tags
            }
        }
    }
    `

export const ADD_TAG_TO_TODO_MUTATION = gql`
    mutation AddTagToTodoItem($todoItemId: ID!, $tagName: String!) {
        addTagToTodoItem(todoItemId: $todoItemId, tagName: $tagName) {
            success
            todoItem {
                id
                title
                description
                completed
                lastCompletedAt
                createdAt
                archived
                order
                tags
            }
        }
    }
    `

export const REMOVE_TAG_FROM_TODO_MUTATION = gql`
    mutation RemoveTagFromTodoItem($todoItemId: ID!, $tagName: String!) {
        removeTagFromTodoItem(todoItemId: $todoItemId, tagName: $tagName) {
            success
            todoItem {
                id
                title
                description
                completed
                lastCompletedAt
                createdAt
                archived
                order
                tags
            }
        }
    }
    `

export const ME_QUERY = gql`
query {
    me {
        id
        email
        role
    }
}
`

export const ALL_LISTS_QUERY = gql`
query GetlAllTodoList {
    getAllTodoLists {
        id
        name
        order
        archived
        createdAt
    }
}
`

export const ADD_LIST_MUTATION = gql`
mutation AddList($name: String!) {
    createTodoList(todoListInput: { name: $name }) {
        success
        todoList {
            id
            name
            order
            archived
            createdAt
        }
    }
}
`

export const ARCHIVE_LIST_MUTATION = gql`
    mutation ArchiveList($id: ID!) {
        archiveTodoList(todoListId: $id) {
            success
            todoList {
                id
                name
                order
                archived
                createdAt
            }
        }
    }
    `

export const CREATE_FOCUS_SESSION = gql`
    mutation CreateFocusSession($todoItemId: ID!, $startedAt: DateTime!, $durationInSeconds: Int!, $quality: Int!) {
        createFocusSession(todoItemId: $todoItemId, startedAt: $startedAt, durationInSeconds: $durationInSeconds, quality: $quality) {
            success
            focusSession {
                id
                startedAt
                durationInSeconds
                quality
            }
        }
    }`
