import { Box } from "@mui/material"
import { useState } from "react"
import { ALL_TODOS_QUERY } from "../GqlOperations";
import { useQuery } from "@apollo/client"
import { ITodoItem } from "../Types"
import { useParams } from "react-router-dom"
import Todo from "./Todo";

interface ITodosProps {
  setFocusedTodoId: (id: string | null) => void
}

const Todos = ({setFocusedTodoId} : ITodosProps) => {
  const params = useParams()
  const todoListId = params.todoListId ? params.todoListId : null
  const { loading, error, data } = useQuery(ALL_TODOS_QUERY, {
    variables: {
      todoListId
    }
  })
  const [editingId, setEditingId] = useState<string | null>(null)


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :( ... maybe try reloading?</p>;
  if (data) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {data.getAllTodoItems.map((todo: ITodoItem) =>{  
          if (todo.archived) return null
          // if (focusedTodoId && focusedTodoId !== todo.id) return null
          return <Box key={todo.id} sx={{
            display: "flex",
            flexDirection: "column",
          }}>
            <Todo
              todo={todo}
              isEditing={editingId === todo.id}
              setEditingId={setEditingId}
              setFocusedTodoId={setFocusedTodoId}
            />
          </Box>
        })}
      </Box>
    );
  }
  return null;
};

export default Todos;