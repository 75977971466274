import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { ITodoItem } from "../Types";
import ArchiveIcon from '@mui/icons-material/Archive'
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { useMutation } from "@apollo/client";
import { ADD_TAG_TO_TODO_MUTATION, ALL_TODOS_QUERY, MOVE_TODO_MUTATION, REMOVE_TAG_FROM_TODO_MUTATION, SAVE_TODO_MUTATION, TOGGLE_ARCHIVE_ITEM_MUTATION, TOGGLE_COMPLETE_ITEM_MUTATION } from "../GqlOperations";
import { useDrag, useDrop } from "react-dnd";

interface ITodoMenuButtonProps {
  todo: ITodoItem;
  isInFocusMode: boolean;
  setFocusedTodoId: (id: string | null) => void;
  archiveTodoCallback: () => void;
}

export const TodoMenuButton = ({
    todo,
    isInFocusMode,
    setFocusedTodoId,
    archiveTodoCallback,
  }: ITodoMenuButtonProps) => {
    const [addTagToTodoMutation] = useMutation(ADD_TAG_TO_TODO_MUTATION, {
      refetchQueries: [
        {
          query: ALL_TODOS_QUERY, // TODO: only refetch the todo list that this todo is in
        },
      ]
    })
    const [addTagDialogOpen, setAddTagDialogOpen] = useState(false)
    const [addTagValue, setAddTagValue] = useState<string>('')
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const openMenu = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
      },
      [setMenuAnchorEl]
    );
    const closeMenu = useCallback(() => {
      setMenuAnchorEl(null);
    }, [setMenuAnchorEl]);
    const toggleFocusMode = useCallback(() => {
      if (isInFocusMode) {
        setFocusedTodoId(null);
      } else {
        setFocusedTodoId(todo.id);
      }
    }, [isInFocusMode, setFocusedTodoId, todo.id]);
    const isMenuOpen = Boolean(menuAnchorEl)
    const closeAddTagDialog = useCallback(() => {
      setAddTagValue('')
      setAddTagDialogOpen(false)
    }, [setAddTagDialogOpen])

    const confirmAddTag = useCallback(() => {
      addTagToTodoMutation({
        variables: {
          todoItemId: todo.id,
          tagName: addTagValue
        }
      })
      setAddTagValue('')
      setAddTagDialogOpen(false)
    }, [addTagValue, setAddTagDialogOpen, addTagToTodoMutation, todo.id])
    return (
      <>
        <IconButton
          size="small"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={openMenu}
          sx={{
            paddingRight: 0,
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={closeMenu}
        >
          <MenuItem
            onClick={() => {
              toggleFocusMode();
              closeMenu();
            }}
          >
            <CenterFocusStrongIcon />
            {isInFocusMode ? " Exit Focus Mode" : " Focus Mode"}
          </MenuItem>
          
          <MenuItem
            onClick={() => {
              setAddTagDialogOpen(true);
              closeMenu();
            }}
          >
            <LocalOfferIcon />
            Add Tag
          </MenuItem>

          <MenuItem
            onClick={() => {
              archiveTodoCallback();
              closeMenu();
            }}
          >
            <ArchiveIcon />
            Archive
          </MenuItem>
        </Menu>
        <Dialog open={addTagDialogOpen} onClose={closeAddTagDialog}>
        <DialogTitle>Add Tag</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tag name to add to this item:
            </DialogContentText>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tag Name"
            type="text"
            fullWidth
            variant="standard"
            value={addTagValue}
            onChange={(e) => setAddTagValue(e.target.value)}
          />
          </DialogContent>
          <DialogActions>
          <Button onClick={closeAddTagDialog}>Cancel</Button>
          <Button onClick={confirmAddTag}>Add</Button>
        </DialogActions>
        </Dialog>
      </>
    );
  }

interface TodoProps {
  todo: ITodoItem;
  isEditing: boolean;
  setEditingId: (id: string | null) => void;
  setFocusedTodoId: (id: string | null) => void;
}

const Todo = ({
  todo,
  isEditing,
  setEditingId,
  setFocusedTodoId,
}: TodoProps) => {
  const [saveTodo] = useMutation(SAVE_TODO_MUTATION)
  const [removeTagFromTodo] = useMutation(REMOVE_TAG_FROM_TODO_MUTATION)
  const [toggleCompleteTodo] = useMutation(TOGGLE_COMPLETE_ITEM_MUTATION)
  const [toggleArchiveTodo] = useMutation(TOGGLE_ARCHIVE_ITEM_MUTATION)
  const [moveTodo] = useMutation(MOVE_TODO_MUTATION, {
      refetchQueries: [ALL_TODOS_QUERY],
    });
  const [editedTitle, setEditedTitle] = useState(todo.title)
  const startEditing = useCallback(() => {
    setEditingId(todo.id);
    setEditedTitle(todo.title);
  }, [setEditingId, setEditedTitle, todo.title, todo.id])

  const archiveTodoCallback = useCallback(() => {
    toggleArchiveTodo({
      variables: {
        id: todo.id,
      },
    });
  }, [toggleArchiveTodo, todo.id]);

  const tagsCompoment = todo.tags.map((tag) => (
    <Chip
      key={tag}
      label={tag}
      size="small"
      onDelete={() => {
        removeTagFromTodo({
          variables: {
            todoItemId: todo.id,
            tagName: tag
          }
        })
      }}
      sx={{
        marginRight: 1,
        display: "flex",
      }}
    />
  ))


  const [ , dragRef, dragPreviewRef] = useDrag(
    () => ({
      type: "TodoItem",
      item: todo,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [todo]
  );

  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: "TodoItem",
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (itemDroppedIn: ITodoItem) => {
        moveTodo({
          variables: {
            id: itemDroppedIn.id,
            toIndex: todo.order,
          },
        });
      },
    }),
    [moveTodo, todo.order]
  );

  return (
    <Box
      ref={canDrop ? dropRef : dragPreviewRef}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: canDrop && isOver ? "lightgray" : "transparent",
      }}
    >
      <TodoMenuButton
        todo={todo}
        isInFocusMode={false}
        setFocusedTodoId={setFocusedTodoId}
        archiveTodoCallback={archiveTodoCallback}
      />
      <IconButton
        ref={dragRef}
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          // visibility: isInFocusMode ? "hidden" : "visible",
        }}
      >
        <DragIndicatorIcon />
      </IconButton>
      <IconButton
        sx={{
          paddingLeft: 0,
        }}
        onClick={() => {
          toggleCompleteTodo({
            variables: {
              id: todo.id,
            },
          });
        }}
      >
        {todo.completed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      </IconButton>

      {isEditing ? (
        <>
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) => setEditedTitle(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                saveTodo({
                  variables: {
                    id: todo.id,
                    title: editedTitle,
                    description: todo.description,
                  },
                });
                setEditingId(null);
              }
              if (e.key === "Escape") {
                setEditingId(null);
              }
            }}
            value={editedTitle}
          ></TextField>
        </>
      ) : (
        <>
          <Typography
            variant="body1"
            onClick={() => startEditing()}
            sx={{
              cursor: "pointer",
              paddingRight: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textDecoration: todo.completed ? "line-through" : "none",
            }}
          >
            {todo.title}
          </Typography>
        </>
      )}
    { tagsCompoment }
    </Box>
  );
};

export default Todo