import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyVfV-SRpITil-uPxBNpT8eP_3T04M8UA",
  authDomain: "smalltodo-1aa34.firebaseapp.com",
  projectId: "smalltodo-1aa34",
  storageBucket: "smalltodo-1aa34.appspot.com",
  messagingSenderId: "342530655784",
  appId: "1:342530655784:web:d0edbb58a5d7f13151171d",
  measurementId: "G-H777C47EMY"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
// const auth = getAuth()
