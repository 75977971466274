import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { PomodoroTimerMachineState, PomodoroTimerMachineSend } from "../state/FocusTimerState";

interface IPomodoroTimerProps {
    pomodoroTimerState: PomodoroTimerMachineState
    pomodoroTimerStateSend: PomodoroTimerMachineSend
}

const PomodoroTimer = ({pomodoroTimerState, pomodoroTimerStateSend} : IPomodoroTimerProps) => {

    const { timeLeft } = pomodoroTimerState.context


    useEffect(() => {
        let interval: NodeJS.Timeout | null = null
    
        if (pomodoroTimerState.value == "active") {
            interval = setInterval(() => {
                pomodoroTimerStateSend("TICK")
            }, 1000)
        }else{
            if (interval) clearInterval(interval);
        }

        return () => {
            if (interval) clearInterval(interval);
        }
    }, [pomodoroTimerState.value, pomodoroTimerStateSend])

    const formatTime = (timeLeft : number) => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
    
        const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
        const secondsStr = seconds < 10 ? `0${seconds}` : seconds;
    
        return `${minutesStr}:${secondsStr}`;
    };
    
    return (
        <Stack direction="row" 
            sx={{
            justifyContent: "center",
            pt: 4,
        }}>
            <Box

                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Typography variant="h4">
                        {formatTime(timeLeft)}
                    </Typography>
                    <Stack direction="row">
                        { pomodoroTimerState.value == "idle" ? (
                            <Button onClick={() => pomodoroTimerStateSend("START")}>
                                Start
                            </Button>
                        ) : null}
                        { (pomodoroTimerState.value == "stopped" || pomodoroTimerState.value == "paused") ? (
                            <Button onClick={() => pomodoroTimerStateSend("RESUME")}>
                                Resume 
                            </Button>
                        ) : null}
                        { pomodoroTimerState.value == "active" ? (
                            <Button onClick={() => pomodoroTimerStateSend("PAUSE")}>
                                Pause
                            </Button>
                        ) : null}
                        { pomodoroTimerState.value == "active" ? (
                            <Button onClick={() => pomodoroTimerStateSend("STOP")}>
                                Stop
                            </Button>
                        ) : null}
                        { pomodoroTimerState.value == "stopped" ? (
                            <Button onClick={() => pomodoroTimerStateSend("COMPLETE")}>
                                Complete
                            </Button>
                        ) : null}
                        { pomodoroTimerState.value == "stopped" ? (
                            <Button onClick={() => pomodoroTimerStateSend("RESET")}>
                                Reset
                            </Button>
                        ) : null}
                    </Stack>
                </Box>
            </Box>
        </Stack>
    )
}

export default PomodoroTimer;
