import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from './FirebaseApp';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { AudioPlayerProvider } from "react-use-audio-player"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const auth = getAuth(firebaseApp)

const httpLink = createHttpLink({
  uri: 'https://smalltodoapi.fly.dev/graphql',
  // uri: 'http://localhost:8000/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  if (auth.currentUser) {
    return auth.currentUser.getIdToken().then((token) => {
      return {
        headers: {
          ...headers,
          "X-Firebase-Auth": token ? `${token}` : "",
        }
      }
    })
  }
  else{
    return {}
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/lists/:todoListId", element: <App />},
  { path: "/focus/:focusedTodoId", element: <App />},
])

root.render(
  <React.StrictMode>
    <AudioPlayerProvider>
      <ApolloProvider client={client}>
        <RouterProvider router={router}/>
      </ApolloProvider>
    </AudioPlayerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
