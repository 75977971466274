import { Button, CssBaseline, Drawer, Stack, ThemeProvider, Typography, createTheme, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { firebaseApp } from './FirebaseApp'
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth'
import { getAuth } from "firebase/auth"
import NavBar from './components/NavBar'
import Todos from './components/Todos'
import SideBar from './components/SideBar'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AddTodo from './components/AddTodo'
import { AddComponentType } from './Types'
import { useQuery } from '@apollo/client'
import { ME_QUERY } from './GqlOperations'
import { useNavigate, useParams } from 'react-router-dom'
import FocusedTodo from './components/FocusedTodo'

const auth = getAuth(firebaseApp)

const LoggedInApp = () => {
  const params = useParams()
  const navigate = useNavigate()
  const focusedTodoId = params.focusedTodoId ? params.focusedTodoId : null
  const [showAddComponentType, setShowAddComponentType] = useState<AddComponentType>("none")
  const {data: meData, loading} = useQuery(ME_QUERY)
  const isFreeUser = !loading && meData.me.role === "free"
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const setFocusedTodoId = useCallback((id: string | null) => {
    if (id) {
      navigate(`/focus/${id}`)
    } else {
      navigate(`/`)
    }
  }, [navigate])

  const handleKeyboardShortcuts = useCallback((e: KeyboardEvent) => {
    if (e.metaKey && e.shiftKey && e.key === "a") {
      e.preventDefault()
      setShowAddComponentType('list')
    }
    else if (e.metaKey && e.key === "a") {
      e.preventDefault()
      setShowAddComponentType('todo')
    }
  },[])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts)
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts)
    }
  }, [handleKeyboardShortcuts])

  if (isFreeUser) {
    return (
      <Box>
        <NavBar 
          isInFocusMode={focusedTodoId !== null} 
          setIsDrawerVisible={setIsDrawerVisible}
          setShowAddComponentType={setShowAddComponentType}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography>
            This application is currently on limited beta. Please email
            hello@taskative.app for access.
          </Typography>
        </Box>
      </Box>
    )
  }

  return (<>
    <NavBar 
      isInFocusMode={focusedTodoId!==null} 
      setIsDrawerVisible={setIsDrawerVisible}
      setShowAddComponentType={setShowAddComponentType}
    />
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
    }}
      onClick={() => {
        setIsDrawerVisible(false)
      }}
    >
      <Drawer
        open={isDrawerVisible}
      >
        <SideBar 
          isInFocusMode={focusedTodoId!=null} 
          />
        </Drawer>
      <Stack direction="column"
        sx={{
          flexGrow: 1,
          // width: "100%",
          // height: "100%",
          // backgroundColor: "red",
        }}
      >
        {showAddComponentType !== "none" ? <AddTodo 
          showAddComponentType={showAddComponentType}
          setShowAddComponentType={setShowAddComponentType}/> : null}
        { focusedTodoId ? <FocusedTodo focusedTodoId={focusedTodoId}/> 
          : <Todos setFocusedTodoId={setFocusedTodoId}/>
        }
      </Stack>
    </Box>
  </>)
}




function App() {
  const [signInWithGoogle] = useSignInWithGoogle(auth)
  const [user, loading] = useAuthState(auth)

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <DndProvider backend={HTML5Backend}>
          {!user && !loading ?  <Button onClick={() => signInWithGoogle()}>Sign in with Google</Button> : null }
          {user && <LoggedInApp/>}
        </DndProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
