import { firebaseApp } from '../FirebaseApp'
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth'
import { getAuth } from "firebase/auth"
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import SettingsIcon from '@mui/icons-material/Settings'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useCallback, useState } from 'react';
import { ALL_LISTS_QUERY, ARCHIVE_LIST_MUTATION, ME_QUERY } from '../GqlOperations';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { AddComponentType, ITodoList } from '../Types'

const auth = getAuth(firebaseApp)

interface INavBarProps {
  isInFocusMode: boolean
  setIsDrawerVisible: (isDrawerVisible: boolean) => void
  setShowAddComponentType: (componentType: AddComponentType) => void
}

const NavBar = ( {isInFocusMode, setIsDrawerVisible, setShowAddComponentType} : INavBarProps) => {
  const {client} = useQuery(ME_QUERY)
  const [user] = useAuthState(auth)
  const [signout] = useSignOut(auth)
  const params = useParams()
  const todoListId = params.todoListId ? params.todoListId : null
  const {data: allListsQueryData } = useQuery(ALL_LISTS_QUERY)
  const navigate = useNavigate()
  const signoutCallback = useCallback(async () => {
    await signout()
    await client.clearStore()
    navigate("/")
  }, [signout, client, navigate])
  const currentTodoList = allListsQueryData?.getAllTodoLists?.find((list: ITodoList) => list.id === todoListId)
  const title = currentTodoList ? currentTodoList.name : "Main"
  const [archiveList] = useMutation(ARCHIVE_LIST_MUTATION, {
    refetchQueries: [
      { query: ALL_LISTS_QUERY}
    ]
  })
  
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<null | HTMLElement>(null)
  const isSettingsMenuOpen = Boolean(settingsMenuAnchorEl)
  const handleArchiveCurrentList = useCallback(async () => {
    await archiveList({variables: {id: String(todoListId)}})
    setSettingsMenuAnchorEl(null)
    navigate("/")
  }, [todoListId, setSettingsMenuAnchorEl, navigate, archiveList])


  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<null | HTMLElement>(null)
  const isAddMenuOpen = Boolean(addMenuAnchorEl)

  return (
    <nav>
      {user ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          visibility: isInFocusMode ? "hidden" : "visible",
        }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              justifyContent: 'flex-start',
            }}
          >
            <IconButton
              onClick={() => {
                setIsDrawerVisible(true)
              }}
            >
              <MenuOpenIcon/>
            </IconButton>
            <Typography sx={{ 
              flexGrow: 1,
              alignSelf: 'center',
            }}>
              {title}
            </Typography>
          </Box>

          <IconButton
            onClick={(event) => {
              setAddMenuAnchorEl(event.currentTarget)
            }}
            >
            <AddCircleOutlineIcon/>
          </IconButton>
          <Menu
            id="navbar-add-menu"
            anchorEl={addMenuAnchorEl}
            onClose={() => setAddMenuAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'navbar-add-menu',
            }}
            open={isAddMenuOpen}>
              <MenuItem onClick={() => {
                setShowAddComponentType("todo")
                setAddMenuAnchorEl(null)
              }
              }>
                <Typography>
                  Add new task
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => {
                setShowAddComponentType("list")
                setAddMenuAnchorEl(null)
              }}>
                <Typography>
                  Add new list
                </Typography>
              </MenuItem>
              
            </Menu>

          <IconButton
            onClick={(event) => {
              setSettingsMenuAnchorEl(event.currentTarget)
            }}
          >
            <SettingsIcon/>
          </IconButton>
          <IconButton 
            onClick={signoutCallback}
          >
            <LogoutIcon/>
          </IconButton>
          <Menu
            id="navbar-settings-menu"
            anchorEl={settingsMenuAnchorEl}
            onClose={() => setSettingsMenuAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'navbar-settings-menu',
            }} 
            open={isSettingsMenuOpen}>
              <MenuItem onClick={handleArchiveCurrentList} disabled={todoListId==null}>
                <Typography>
                  Archive current list
                </Typography>
              </MenuItem>
          </Menu>
        </Box>
      ) : null}
    </nav>
  );
};

export default NavBar;