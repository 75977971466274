import { Box, Button, Skeleton } from "@mui/material";
import { useQuery } from "@apollo/client";
import { ALL_LISTS_QUERY } from "../GqlOperations";
import { ITodoList } from "../Types";
import { useNavigate, useParams } from "react-router-dom";

const AllListsNavbar = () => {
  const {data, loading } = useQuery(ALL_LISTS_QUERY)
  const navigate = useNavigate()
  const params = useParams()
  const currentTodoListId = params.todoListId ? params.todoListId : null
  if(loading) return (<Box sx={{ width: 300 }}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
  </Box>)
  const lists = data.getAllTodoLists?.map((list: ITodoList) => {
    return (
      <Button key={list.id}
        variant={list.id === currentTodoListId ? "contained" : "text"}
        onClick={() => navigate(`/lists/${list.id}`)}
      >{list.name}</Button>
    )
  })
  return <>
    <Button 
        onClick={() => navigate(`/`)}
        variant={currentTodoListId === null ? "contained" : "text"}
    >main</Button>
    {lists}
  </>
}

interface ISidebarProps {
  isInFocusMode: boolean
}

const SideBar = ({isInFocusMode} : ISidebarProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 2,
        paddingRight: 2,
        maxWidth: 200,
        visibility: isInFocusMode ? "hidden" : "visible",
      }}
    >
      <AllListsNavbar/> 
    </Box>
  );
}

export default SideBar;
