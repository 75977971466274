import { createMachine, assign, sendTo, StateFrom, Sender } from 'xstate'
import { useMachine } from '@xstate/react'

export const pomodoroTimerMachine = createMachine({
  predictableActionArguments: true,
  id: "timer",
  initial: "idle",
  context: {
    // initial values
    timeLeft: 0,
    startTime: null,
    totalSessionTime: 25 * 60,
    // totalSessionTime: 3,
    totalElapsedSessionTime: 0,
  } as {
    timeLeft: number;
    startTime: Date | null;
    totalSessionTime: number;
    totalElapsedSessionTime: number;
  },
  states: {
    idle: {
      entry: assign({
        timeLeft: (context) => context.totalSessionTime,
      }),
      on: {
        START: {
          target: "active",
          actions: ["onStart"],
        },
      },
    },
    active: {
      on: {
        PAUSE: "paused",
        STOP: "stopped",
        COMPLETE: "completed",
        TICK: [{
          actions: ["onTick"],
          cond: (context) => context.timeLeft === 1,
          target: "completed",
        },{
          actions: ["onTick"],
        }],
      },
    },
    paused: {
      on: {
        RESUME: "active",
      },
    },
    stopped: {
      on: {
        RESUME: "active",
        RESET: "idle",
        COMPLETE: "completed"
      },
    },
    completed: {
      entry: assign({
        totalElapsedSessionTime: (context) => context.totalSessionTime - context.timeLeft,
      }),
      on: {
        RESET: "idle",
      },
    },
  },
},{
  actions: {
    onTick: (context, event) => {
      context.timeLeft = context.timeLeft - 1
    },
    onStart: (context, event) => {
      context.startTime = new Date()
    },
  }
});

export type UseMachinePomodoroTimerMachineReturnType = ReturnType<typeof useMachine<typeof pomodoroTimerMachine>>
export type PomodoroTimerMachineState = UseMachinePomodoroTimerMachineReturnType[0]
export type PomodoroTimerMachineSend = UseMachinePomodoroTimerMachineReturnType[1]