import { useMutation, useQuery } from "@apollo/client"
import { CREATE_FOCUS_SESSION, GET_TODO_QUERY } from "../GqlOperations"
import PomodoroTimer from "./PomodoroTimer"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Rating, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { pomodoroTimerMachine } from "../state/FocusTimerState"
import { useMachine } from '@xstate/react';
import { useAudioPlayer } from "react-use-audio-player"

interface ICompleteFocusDialogProps {
    open: boolean
    handleClose: () => void
    handleSubmit: (qualityRating: number) => void
    ratingValue: number | null
    setRatingValue: (value: number | null) => void
}

const CompleteFocusDialog = ({open, handleClose, handleSubmit, ratingValue, setRatingValue} : ICompleteFocusDialogProps) => {
    return(
        <Dialog 
            open={open} 
            onClose={() => {
                handleClose()
            }}>
            <DialogTitle>
                Good Job!
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                        You've completed a focus session. How would you rate your focus?
                </DialogContentText>
                <Rating
                    name="simple-controlled"
                    value={ratingValue}
                    onChange={(event, newValue) => {
                        setRatingValue(newValue)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={() => {
                        if(ratingValue!==null){
                            handleSubmit(ratingValue)
                        }
                        }}
                    disabled={ratingValue===null}
                >Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

interface IFocusedTodoProps {
    focusedTodoId: string | null
}

const FocusedTodo = ( {focusedTodoId} : IFocusedTodoProps) => {
    const {loading, error, data} = useQuery(GET_TODO_QUERY, {
        variables: {
            id: focusedTodoId
        }
    })
    const { play, pause, stop, togglePlayPause, ready, playing } = useAudioPlayer({
            src: '/sounds/ringtone-126505.mp3',
            format: 'mp3',
    })

    const [pomodoroTimerState, pomodoroTimerStateSend] = useMachine(pomodoroTimerMachine)
    const [openCompleteDialog, setOpenCompleteDialog] = useState(false)
    const [ratingValue, setRatingValue] = useState<number | null>(null)
    const [createFocusSession] = useMutation(CREATE_FOCUS_SESSION)

    useEffect(() => {
        if (pomodoroTimerState.value == "completed") {
            setRatingValue(null)
            setOpenCompleteDialog(true)
            play()
        }
    }, [pomodoroTimerState.value])

    useEffect(() => {
        if(playing){
            stop()
        }
    }, [ratingValue])

    const onCloseCompleteDialog = useCallback(() => {
        // TODO: maybe don't allow this
        // pomodoroTimerStateSend("RESET")
        // setOpenCompleteDialog(false)
    }, [])

    const handleSubmit = useCallback(async () => {
        await createFocusSession({
            variables: {
                todoItemId: focusedTodoId,
                startedAt: pomodoroTimerState.context.startTime?.toISOString(),
                durationInSeconds: pomodoroTimerState.context.totalElapsedSessionTime,
                quality: ratingValue,
            }
        })
        pomodoroTimerStateSend("RESET")
        setOpenCompleteDialog(false)
    }, [onCloseCompleteDialog, ratingValue, pomodoroTimerState])

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :( ... maybe try reloading?</p>
    return(
        <Stack>
            <Box
                sx={{
                    padding: "1rem",
                    paddingX: "2rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <h1>{data.getTodoItem.title}</h1>
            </Box>
            <PomodoroTimer 
                pomodoroTimerState={pomodoroTimerState}
                pomodoroTimerStateSend={pomodoroTimerStateSend}
            />
            <CompleteFocusDialog 
                open={openCompleteDialog} 
                handleClose={onCloseCompleteDialog} 
                handleSubmit={handleSubmit}
                setRatingValue={setRatingValue}
                ratingValue={ratingValue}
            />
        </Stack>
    )
}

export default FocusedTodo