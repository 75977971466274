import { gql, useMutation } from "@apollo/client";
import { Button, IconButton, Stack, TextField } from "@mui/material"
import { useCallback, useRef, useState } from "react";
import { ADD_LIST_MUTATION, ADD_TODO_MUTATION, ALL_LISTS_QUERY, ALL_TODOS_QUERY } from "../GqlOperations"
import AddIcon from '@mui/icons-material/Add'
import { AddComponentType } from "../Types";
import { useNavigate, useParams } from "react-router-dom";

interface IAddTodoProps {
    showAddComponentType: AddComponentType
    setShowAddComponentType: (componentType: AddComponentType) => void
}

const AddTodo = ( {showAddComponentType, setShowAddComponentType} : IAddTodoProps) => {
  const navigate = useNavigate()
  const params = useParams()
  const todoListId = params.todoListId ? params.todoListId : null

  const inputRef = useRef<HTMLInputElement>(null)

  const lastValue = useRef<AddComponentType>("none")
  if (lastValue.current == "none" && lastValue.current !== showAddComponentType) {
    lastValue.current = showAddComponentType
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const [addTodo] = useMutation(ADD_TODO_MUTATION, {
    refetchQueries: [
      { 
        query: ALL_TODOS_QUERY,
        variables: {
          todoListId
        }
      }
    ],
    update: (cache, {data: {createTodoItem}}) => {
      if (createTodoItem.success) {
        cache.modify({
          fields: {
            getAllTodoItems(existingTodos = []){
              const newTodoRef = cache.writeFragment({
                data: createTodoItem.todoItem,
                fragment: gql`
                  fragment NewOptimisticTodo on TodoItem {
                    __typename
                    id
                    title
                    description
                    completed
                    lastCompletedAt
                    createdAt
                    archived
                    order
                    tags
                    }
                  `
              })
              return [newTodoRef, ...existingTodos]
            }
          }
        })
      }
    },
  })
  const [addList] = useMutation(ADD_LIST_MUTATION, {
    refetchQueries: [
      { query: ALL_LISTS_QUERY }
    ]
  })
  const [value, setValue] = useState("")

  const onAddClick = useCallback(async () => {
    if(showAddComponentType === "list") {
      const addListData = await addList({variables:{name: value}})
      if(addListData?.data?.createTodoList?.todoList?.id) {
        navigate(`/lists/${addListData.data.createTodoList.todoList.id}`)
      }
    } else {
      addTodo({
        variables:{text: value, description: "", todoListId},
        optimisticResponse: {
          createTodoItem: {
            __typename: "TodoItemMutationResult",
            success: true,
            todoItem: {
              __typename: "TodoItem",
              id: "tempId",
              title: value,
              description: "",
              completed: false,
              lastCompletedAt: null,
              createdAt: new Date().toISOString(),
              tags: [],
              archived: false,
              order: 0,
            }
          }
        }
      })
    }
    setShowAddComponentType("none")
    setValue("")
  }, [addTodo, setShowAddComponentType, setValue, value, showAddComponentType, navigate, addList, todoListId])

  const textFieldLabel = showAddComponentType === "todo" ? "Add Todo" : "Add List"

  return (
    <Stack
      direction="row"
    > 
      <IconButton sx={{visibility: "hidden"}}>
        <AddIcon/>
      </IconButton>
      <Stack direction="column"
        sx={{
          flexGrow: 1,
        }}
      >
        <TextField
          inputRef={inputRef}
          variant="standard"
          label={textFieldLabel}
          sx={{
            width: "100%",
          }}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              void onAddClick()
            }else if(e.key === "Escape") {
              void setShowAddComponentType("none")
            }
          }}
        >
        {value}
        </TextField>
        <Stack direction={"row"} spacing={2}>
          <Button onClick={() => setShowAddComponentType("none")}>Cancel</Button>
          <Button onClick={onAddClick}>Add</Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddTodo;